import * as Yup from 'yup';
import { DataModelFieldObj } from '../../../models/Product';
import { AnalyticsDateRange } from "../../../models/App";
import moment from "moment";
import {
  GA4GoogleAnalyticsData,
  GA4GoogleAnalyticsDataRow,
  ParsedAnalyticsData
} from "../../../models/Analytics";

// helper functions used by google analytics settings form

// generates analytics model and initial values when loading the form

export function createAnalyticsData () {
  const dataModel: Record<string, DataModelFieldObj<any>> = analyticsModel();
  const initialValues: Record<string, any> = {};
  Object.keys(dataModel).forEach(field => initialValues[field] = dataModel[field].initialValue);
  return initialValues
}

// generates validation schema for RHF and adds custom Yup validations when initializing form
export function createValidationSchema () {
  const dataModel: Record<string, DataModelFieldObj<any>> = analyticsModel();
  const schema: Record<string, any> = {};
  Object.keys(dataModel).forEach(field => schema[field] = dataModel[field].validationScheme());
  return schema
}

// data source for generating analytics model/initial values when loading the form
// also used to initialize validation schema and custom Yup methods
export const analyticsModel = () => {
  return {
    clientEmail: {
      initialValue: null,
      validationScheme: () => Yup.string().nullable().required('Required field'),
    },
    privateKey: {
      initialValue: null,
      validationScheme: () => Yup.string().nullable().required('Required field'),
    },
    applicationName: {
      initialValue: null,
      validationScheme: () => Yup.string().nullable().required('Required field'),
    },
    propertyId: {
      initialValue: null,
      validationScheme: () => Yup.string().nullable().required('Required field'),
    },
  }
};

/**
 * converts a MM-DD-YYYY date range (used by 'old' UA google analytics API) to the
 * YYYY-MM-DD format (used by 'new' GA4 google analytics API).  allows the date picker to use a single
 * format, which is converted when making the API request.
 * @param {AnalyticsDateRange} dateRange
 */
export const convertDatesForGA4 = (dateRange: AnalyticsDateRange) => {
  return {
    startDate: moment(dateRange.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
    endDate: moment(dateRange.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
  }
}

/**
 * Extracts GA4 row metric data so totals can be displayed on cards on the dashboard
 * @param {GA4GoogleAnalyticsDataRow[]} metrics
 * @param {number} metricIndex
 */

export const getGA4DashboardCardData = (metrics: GA4GoogleAnalyticsDataRow[], metricIndex: number) => {
  const data = metrics.length > 0 ? parseFloat(metrics[0].metricValues[metricIndex].value) : 0
  return data
}

/**
 * Prepares GA4 top revenue product data for display in a table in our UI.  In some implementations,
 * image field will be populated later in an effect so image tooltips can be shown.
 *
 * @param {GA4GoogleAnalyticsData} analyticsData
 */
export const parseGA4TopRevenueData = (analyticsData: GA4GoogleAnalyticsData) => {
  let tableRows = [] as ParsedAnalyticsData[];

  analyticsData.rows.forEach((rowData: GA4GoogleAnalyticsDataRow) => {
    tableRows.push({
      "dimensions": rowData.dimensionValues[0].value,
      "dimensions2": rowData.dimensionValues[1].value,
      "metrics1": rowData.metricValues[0].value,
      "metrics2": rowData.metricValues[1].value,
      "image": undefined
    })
  })
  return tableRows;
};

/**
 * Prepares GA4 traffic data for display in a bar chart.  x-axis is date dimension,
 * y-axis is a metric returned by the API - sessions, total users, new users, and bounce rate.
 * We convert bounce rate to total bounced sessions/day by multiplying it by session data
 * (rowData.metricValues[0]).  This keeps it consistent with UA data.
 * @param {GA4GoogleAnalyticsData} analyticsData
 */
export const parseGA4TrafficChartData = (analyticsData: GA4GoogleAnalyticsData) => {
  let chartDates: string[] = []
  let chartMetrics: any = {
    metrics1: [],
    metrics2: [],
    metrics3: [],
    metrics4: [],
  }
  analyticsData.rows.forEach((rowData: any) => {
    chartDates.push(rowData.dimensionValues[0].value)
    chartMetrics.metrics1.push(rowData.metricValues[0].value)
    chartMetrics.metrics2.push(rowData.metricValues[1].value)
    chartMetrics.metrics3.push(rowData.metricValues[2].value)
    chartMetrics.metrics4.push(rowData.metricValues[3].value * rowData.metricValues[0].value)
  })
  return {
    labels: chartDates,
    data1: chartMetrics.metrics1,
    data2: chartMetrics.metrics2,
    data3: chartMetrics.metrics3,
    data4: chartMetrics.metrics4,
  };
}

/**
 * Prepares ecommerce GA4 data for display in a bar chart.  x-axis is date dimension,
 * y-axis is an ecommerce metric returned by the API - purchaseRevenue, averagePurchaseRevenue,
 * transactionsPerPurchaser, or itemsPurchased.
 * @param {GA4GoogleAnalyticsData} analyticsData
 */
export const parseGA4EcommerceChartData = (analyticsData: GA4GoogleAnalyticsData) => {
  let chartDates: string[] = []
  let chartMetrics: any = {
    metrics1: [],
    metrics2: [],
    metrics3: [],
    metrics4: [],
  }
  analyticsData.rows.forEach((rowData: any) => {
    chartDates.push(rowData.dimensionValues[0].value)
    // if showing a rate metric, multiple by 100 to show a percentage
    if (analyticsData.metricHeaders[0].name && analyticsData.metricHeaders[0].name === 'sessionConversionRate') {
      chartMetrics.metrics1.push(rowData.metricValues[0].value * 100)
    }
    else chartMetrics.metrics1.push(rowData.metricValues[0].value)
  })
  return {
    labels: chartDates,
    data1: chartMetrics.metrics1,
    data2: chartMetrics.metrics2
  };
}
